var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('good-table-column-search',{attrs:{"moduleapi":((this.baseApi) + "/sales"),"title":"Sales","columns":_vm.columns,"routerpath":"/crm/sales/addsales","formOptions":_vm.formOptions,"edit":_vm.edit,"add":_vm.add,"bulksales":true,"canDelete":_vm.canDelete,"viewComponent":"salesshow","newPage":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
var getGridData = ref.getGridData;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[(_vm.approvalAccess && row.approved1 !== 'Approved')?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"primary","id":'sales-approve' + row.id},on:{"click":function($event){return _vm.approveRow(row, 'Approved', getGridData)}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):_vm._e(),(_vm.approvalAccess && row.approved1 !== 'Approved')?_c('b-tooltip',{attrs:{"target":'sales-approve' + row.id,"triggers":"hover"}},[_vm._v(" Approve ")]):_vm._e(),(
                      _vm.approvalAccess &&
                      row.receivedamount == 0 &&
                      row.approved1 != 'Rejected'
                    )?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"danger","id":'sales-reject' + row.id},on:{"click":function($event){return _vm.approveRow(row, 'Rejected', getGridData)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),(
                      _vm.approvalAccess &&
                      row.receivedamount == 0 &&
                      row.approved1 != 'Rejected'
                    )?_c('b-tooltip',{attrs:{"target":'sales-reject' + row.id,"triggers":"hover"}},[_vm._v(" Reject ")]):_vm._e()],1)])]}},{key:"customField",fn:function(ref){
                    var props = ref.props;
return [(props.column.field === 'plotareasqyard')?_c('span',[_vm._v(" "+_vm._s(_vm.twoDecimal(props.row.plotareasqfeet / 9))+" ")]):(props.column.field === 'plcharges')?_c('span',[_vm._v(" "+_vm._s(_vm.twoDecimal(props.row.gardenphasevalue + _vm.cornerphasevalue))+" ")]):_vm._e()]}}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }